import React, { useRef, useLayoutEffect } from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text } from '@/components/Text'
import { Wrapper, StyledDivider } from './styled'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import gsap from 'gsap'

const PlanetPage: React.FC = () => {
  const { t } = useTranslation()
  const dividerRef = useRef(null)

  useLayoutEffect(() => {
    const tl = gsap.timeline({})
    tl.fromTo(
      dividerRef.current,
      1.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  })
  return (
    <>
      <Page
        content={
          <PageWrapper>
            <Wrapper>
              <Text
                title={t('dna.join_toggle3_title', {
                  defaultValue: 'WE VALUE COMPANIONSHIP',
                })}
                text={t('dna.join_toggle3_body', {
                  defaultValue:
                    'We stick together. If somebody from us needs us, we will be there for them. This can be a small request for help, it can be an important migration weekend, it can be a huge bug fixing challenge. It can even be a personal situation. At such a moment, we say: ’I will be there for you. I have your back. So we work together, struggle together and party together. We go above and beyond for each other.',
                })}
              />
              <StyledDivider ref={dividerRef} />
            </Wrapper>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/values"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default PlanetPage
